import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

import {
  debounceTime,
  tap,
  switchMap,
  finalize,
  distinctUntilChanged,
  filter,
} from "rxjs/operators";

const API_KEY = "e8067b53";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
})
export class SearchComponent implements OnInit {
  searchMoviesCtrl = new FormControl();
  filteredMovies: Result[];
  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  selectedMovie: any = "";

  constructor(private http: HttpClient) {}

  onSelected() {
    console.log(this.selectedMovie);
    this.selectedMovie = this.selectedMovie;
  }

  displayWith(value: any) {
    if (value) {
      return value.Title;
    }
  }

  clearSelection() {
    this.selectedMovie = "";
    this.filteredMovies = [];
  }

  ngOnInit() {
    this.searchMoviesCtrl.valueChanges
      .pipe(
        filter((res) => {
          return res !== null && res.length >= this.minLengthTerm;
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = "";
          this.filteredMovies = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
        {
          value = value.replaceAll(' ', '%20');


          return this.http
            .get("https://imdb8.p.rapidapi.com/auto-complete?q=" + value)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        })
      )
      .subscribe((data: any) => {
        console.log('data');
        console.log(data);
        if (data["Error"] !== undefined) {
          this.errorMsg = data["Error"];
          this.filteredMovies = [];
        } else {
          this.errorMsg = "";
          this.filteredMovies = data.d;
        }
        console.log(this.filteredMovies);
      });
  }
  getUrlIMDB(id: string): string {
    return 'https://www.imdb.com/title/' + id;
  }

  test() {

  }
}

export interface Image {
  height: number;
  imageUrl: string;
  width: number;
}

export interface Result {
  i: Image;
  id: string;
  l: string;
  q: string;
  qid: string;
  rank: number;
  s: string;
  y: number;
}
