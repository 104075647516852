import { Component } from '@angular/core';

@Component({
  selector: 'app-home-layout',
  template: `
    <app-sidebar></app-sidebar>
  `,
  styles: []
})
export class HomeLayoutComponent {}
