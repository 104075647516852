import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { BreakpointObserver } from '@angular/cdk/layout';

export interface TableElement {
  id: string;
  name: string;
  email: string;
  website: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent {
  @ViewChild(MatSidenav, {static: true}) sidenav!: MatSidenav;
  constructor(
    private observer: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
        this.cdr.detectChanges();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
        this.cdr.detectChanges();
      }
    });
  }

  ngAfterContentChecked(): void {

    this.cdr.detectChanges();
 }

}
