import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login/login.component';
import { RegistrationComponent } from './login/registration/registration.component';
import {
  AuthGuardService as AuthGuard
} from './auth/auth-guard.service';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { SearchComponent } from './search/search.component';
import { HomeComponent } from './home/home.component';


// const routes: Routes = [
//   { path: 'register', component: RegistrationComponent,
//   canActivate: [AuthGuard] },
//   { path: 'login', component: LoginComponent },
//   { path: '**', redirectTo: '' }
// ];

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {
        path: 'search',
        canActivate: [AuthGuard],
        component: SearchComponent
      },
      {
        path: '**',
        canActivate: [AuthGuard],
        component: HomeComponent
      },
    ]
  }
  // {
  //   path: '**',
  //   canActivate: [AuthGuard],
  //   component: HomeComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: HomeComponent
  //     }
  //   ]
  // }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
