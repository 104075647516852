import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { Credentials } from 'src/app/models/Credentials';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  togglePencil = true;
  subscriptionLogin = new Subscription();
  public form: FormGroup;
  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private http: HttpClient,
    private authService: AuthService,
    private router: Router) {

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4),
        passwordValidator() // <-- Here's how you pass in the custom validator.
      ]]
    });
  }

  getErrorMessage(form : FormControl) {

    if(form.hasError('minlength'))
    {
      return 'Inserisci almeno 8 caratteri';
    }
    else if (form.hasError('required')) {
      return 'Campo obbligatorio';
    }
    else if(form.hasError('email')){
      return 'Formato email non corretto';
    }
    else if(form.hasError('passwordStrength')){
      return 'Inserisci almeno una maiuscola una minuscola un numero ed un carattere speciale';
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptionLogin.unsubscribe();
  }

  login() {
    const credentials = this.form.value as Credentials;
    if (credentials.email && credentials.password) {
      this.subscriptionLogin = this.authService.login(credentials)
        .subscribe(
          (val) => {
            if(val.isSuccess)
            {
              // console.log("User is logged your token is:  " + val);
              this.snackBar.open('Login effettuato con successo', 'X', {
                duration: 3000,
                panelClass: ['snackBar-success'],
                horizontalPosition: 'right',
                verticalPosition: 'top',
              });

              this.router.navigateByUrl('/home');
            }
            else {
              this.snackBar.open('Email o password errati', 'X', {
                panelClass: ['snackBar-warn'],
                duration: 3000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
              });
            }
          },
          //res => console.log('HTTP response', res),
          err => {
            console.log(err)
            let snackBarError = this.snackBar.open('HTTP Error' + JSON.stringify(err.error.errors), 'X', {
              panelClass: ['snackBar-error'],
              //duration: 5000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
            });
            snackBarError.onAction().subscribe(() => {
              snackBarError.dismiss();
            });
          }
        )
    }


    ////

  }

  // const courses$ = this.http
  //   .get("https://localhost:44365/api/utenti/index")
  //   .subscribe();


  ////
  testAuth(){
    const courses$ = this.http
    .get("https://localhost:44365/weatherforecast/testauth")
    .subscribe();
  }

  toggle(){
    this.togglePencil = false;
  }

  close(){
    this.togglePencil = true;
  }

  test() {
  }
}

export function passwordValidator(): ValidatorFn {
  // return (control: AbstractControl): ValidationErrors | null => {
  //   const forbidden = nameRe.test(control.value);
  //   return forbidden ? {forbiddenName: {value: control.value}} : null;
  // };
  return (control:AbstractControl) : ValidationErrors | null => {

    const value = control.value;

    if (!value) {
        return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);

    const hasLowerCase = /[a-z]+/.test(value);

    const hasNumeric = /[0-9]+/.test(value);

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;

    return !passwordValid ? {passwordStrength:true}: null;
}
}
// document.querySelector('.toggle').addEventListener('click', function() {
//   document.querySelector('.container').classList.add('active');
// });

// document.querySelector('.close').addEventListener('click', function() {
//   document.querySelector('.container').classList.remove('active');
// });
