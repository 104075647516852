import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { RestApiService } from './rest-api.service';
import { MatSidenav } from '@angular/material';
import { BreakpointObserver } from '@angular/cdk/layout';

// export interface TableElement {
//   id: string;
//   name: string;
//   email: string;
//   website: string;
// }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // @ViewChild(MatSidenav, {static: true}) sidenav!: MatSidenav;
  // Data: TableElement[] = [];
  // col: string[] = ['id', 'name', 'email', 'website'];
  // dataSource = new MatTableDataSource<TableElement>(this.Data);
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    // private observer: BreakpointObserver,
    // private cdr: ChangeDetectorRef,
    ) {}

//   ngAfterViewInit() {
//     this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
//       if (res.matches) {
//         this.sidenav.mode = 'over';
//         this.sidenav.close();
//         this.cdr.detectChanges();
//       } else {
//         this.sidenav.mode = 'side';
//         this.sidenav.open();
//         this.cdr.detectChanges();
//       }
//     });
//   }

//   ngAfterContentChecked(): void {

//     this.cdr.detectChanges();
//  }

  // constructor(private restApiService: RestApiService) {
  //   //  this.dataSource = new MatTableDataSource<TableElement>(res);
  //   //   setTimeout(() => {
  //   //     this.dataSource.paginator = this.paginator;
  //   //   }, 0); this.restApiService.getUsers().subscribe((res) => {

  //   // })
  // }
}
