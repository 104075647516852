import { LoginResult } from '../models/Result';
import { Credentials } from '../models/Credentials';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import jwt_decode, { JwtDecodeOptions, JwtPayload } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})

export class AuthService {


  // token: string =  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.MfCeRENW03FnKzGqKFv7PMGXrysmxlGUw5JtnQE2x58';
  private _token: string;

  public get token() {
    return localStorage.getItem('token');
  }

  constructor(private http: HttpClient) {
  }

  public isAuthenticated(): boolean {    // Get token from localstorage
    let token = localStorage.getItem('token');    // Check if token is null or empty
    if (token){
      const decoded : JwtPayload = jwt_decode(token);
      const expire = decoded.exp * 1000;
      return expire > (new Date()).getDate();
    }
    else{
      return false
    }
  }

  login(credentials: Credentials): Observable<LoginResult> {
    return this.http.post<LoginResult>("https://myboardgames.net/api/login", credentials).pipe(tap((val) => {
      localStorage.setItem('token', val.token)
    } ));
  }
}
